import React from 'react';
import { useHistory } from 'react-router';

import { delay } from '../../util/ExtraMethods';

export const ReferralContext = React.createContext();

export default ({ children }) => {
  const history = useHistory();
  const [voucher, setVoucher] = React.useState();
  const [referral, setReferral] = React.useState();
  const [pageState, setPageState] = React.useState(1);

  const movePage = React.useCallback(async (nextPage) => {
    await delay(200);
    history.push(nextPage);
  }, [history]);

  const store = {
    voucher,
    referral,
    pageState,
    setVoucher,
    setReferral,
    setPageState,
    goTo: movePage,
  }
  return (
    <ReferralContext.Provider value={store}>
      {children}
    </ReferralContext.Provider>
  );
}
