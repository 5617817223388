import React, { useContext } from 'react';
import { AppContext } from '../../../containers/AppContext';
import css from './Button.module.css';

const Button = (props) => {
  const { theme } = useContext(AppContext);
  const isMain = props.type === 'main' ? css.MainButton : '';
  const isLink = props.type === 'link' ? css.LinkButton : '';
  const isSquare = props.variant === 'square' ? css.SquareButton : '';
  const hasSize = props.size ? css[`ButtonSize__${props.size}`] : '';
  return (
    <button
      {...props}
      style={theme.button}
      className={[
        css.Button,
        isMain,
        isLink,
        isSquare,
        hasSize,
        props.className,
      ].join(' ')}
    >
      {props.children}
    </button>
  );
};
export default Button;
