import React from 'react';

export const AppContext = React.createContext();

const Provider = props => {
  const {pubKey} = props;
  const [shop, setShop] = React.useState();
  const [theme, setTheme] = React.useState({});
  const store = {
    shop,
    theme,
    pubKey,
    setShop,
    setTheme,
  };
  return (
    <AppContext.Provider value={store}>
      {props.children}
    </AppContext.Provider>
  );
};

export default Provider;
