import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import App from './App';
import { preparePubKey } from './util/lib';
import Provider from './containers/AppContext';
import * as serviceWorker from './serviceWorker';

const init = async () => {
  const pubKey = await preparePubKey();
  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider pubKey={pubKey}>
          <App />
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

// init app
init();
