import React from 'react';
import { useParams } from 'react-router';

import css from './ReviewError.module.css';

const ReviewError = (props) => {
  const {code} = useParams();
  const {review} = props;
  return (
    <div className={css.Container}>
      {review && (
        <p className={css.Title}>
          Hi, {review.firstName}
        </p>
      )}
      <p className={css.Subtitle}>
        There is a problem while fetching your review request #{code}
      </p>
      <p className={css.Intro}>
        Please contact support if problem persist.
      </p>
    </div>
  )
}
export default ReviewError;
