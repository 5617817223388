import React from 'react';
import TextContainer from '../../UI/Content/TextContainer';

import ReferralPage from '../ReferralPage/Referral.page';

const RedeemSuccess = ({ voucher }) => {
  if (!voucher) {
    return <div>Loading...</div>;
  }
  return (
    <ReferralPage hideButton title='Thank you.'>
      <TextContainer>
        You will receive an SMS with a code that you can use to redeem the gift at the shop.
      </TextContainer>
      <br />
      {voucher.statusCode === 200 && (
        <TextContainer weight='light'>
          Please check your phone to see the code.
        </TextContainer>
      )}
    </ReferralPage>
  );
};
export default RedeemSuccess;
