import React, { useEffect, useState } from 'react';

import css from './Rating.module.css';

export default (props) => {
  const [rating, setRating] = useState(props.rating);
  const [tempRating, setTempRating] = useState();
  useEffect(() => {
    setRating(props.rating);
  }, [props.rating]);

  const rate = (rating) => {
    if (props.disabled) return;
    setRating(rating);
    setTempRating(rating);
    if (props.onChange) props.onChange(rating);
  }
  const starOver = (rating) => {
    if (props.disabled) return;
    setRating(rating);
  }
  const starOut = () => {
    if (props.disabled) return;
    setRating(tempRating);
  }

  const calculate = (expected) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      let klass = [css.Star, props.cssStar];
      if (expected >= i && expected != null) {
        klass.push(css.Selected);
        if (props.cssSelected) {
          klass.push(props.cssSelected);
        }
      }
      if (props.disabled) {
        klass.push(css.Disabled);
      }
      stars.push(
        <label
          key={i}
          className={klass.join(' ')}
          onClick={() => rate(i)}
          onMouseOver={() => starOver(i)}
          onMouseOut={() => starOut()}>
          ★
        </label>
      );
    }
    return stars;
  }

  const stars = calculate(props.display ? props.rating : rating);
  return (
    <div className={[css.Rating, props.css].join(' ')}>
      {stars}
    </div>
  );
}
