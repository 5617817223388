import React from 'react';
import './LoadingAnimation.css';

const LoadingAnimation = (props) => (
	<div className="lds-spinner">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
);
export default LoadingAnimation;
