export const delay = (t, v) => {
	return new Promise(function (resolve) {
		setTimeout(resolve.bind(null, v), t);
	});
};

export const getLink = (name, reviewLinks) => {
	if (!reviewLinks) return;
	let found
	reviewLinks.map(link => {
		if (link.name === name) found = { ...link};
		return false;
	})
	return found;
}

export const sanitizePhone = (phone, leadingZero = true) => {
  if (!phone) return
  phone = (phone + '').replace(/[^0-9]+/g, '')
  if (leadingZero) {
    const cc = '61'
    phone = phone
      .replace(new RegExp(`^${cc}`), '0')
      .replace(new RegExp('^00'), '0')
      .trim()
    // apply leading zero
    if (phone.charAt(0) !== '0') {
      phone = `0${phone}`
    }
  }
  return phone
}
