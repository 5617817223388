import React, { useState } from 'react';
import { useHistory } from 'react-router';

import Button from '../UI/Buttons/Button';
import Content from '../UI/Content/Content';
import { delay } from '../../util/ExtraMethods';
import ReviewLogo from '../UI/ReviewLogo/ReviewLogo';
import TextContainer from '../UI/Content/TextContainer';
import ThumbsButton from '../UI/Buttons/ThumbsButton/ThumbsButton';
import ButtonsContainer from '../UI/Buttons/ButtonsContainer/ButtonsContainer';

const ReviewPage = (props) => {
	const history = useHistory();
	const [pageState, setPageState] = useState(1);
	const {
		fallbackPage,
		reviewLogo,
		textContent,
		buttonType,
		successPage,
	} = props;
	const onSuccess = () => {
		if (props.onSuccess) {
			props.onSuccess();
		}
		movePage(successPage);
	}
	const onFallback = () => {
		if (props.onFallback) {
			props.onFallback();
		}
		movePage(fallbackPage);
	}
	const movePage = async (nextPage) => {
		setPageState(0);
		await delay(400);
		history.push(nextPage);
	};
	return (
		<Content state={pageState}>
			<TextContainer>
				{reviewLogo !== '0' && <ReviewLogo>{props.children}</ReviewLogo>}

				{textContent}
			</TextContainer>
			{buttonType === 'thumbs' ? (
				<ButtonsContainer plain>
					<ThumbsButton onClick={onFallback} />
					<ThumbsButton
						onClick={onSuccess}
						thumbsup="true"
					/>
				</ButtonsContainer>
			) : (
				<ButtonsContainer>
					<Button onClick={onFallback}>No</Button>
					<Button onClick={onSuccess} type="main">
						Yes
					</Button>
				</ButtonsContainer>
			)}
		</Content>
	);
};
export default ReviewPage;
