import React from 'react';
import css from './ButtonsContainer.module.css';

const ButtonsContainer = (props) => {
	return <div className={[
		css.ButtonsContainer,
		props.flexColumn && css.ButtonsContainerFlex,
		!props.plain && css.ButtonsContainerDecorated,
	].join(' ')} style={props.style}>
		{props.children}
	</div>;
};
export default ButtonsContainer;
