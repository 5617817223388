import React from 'react';
import { useParams } from 'react-router';

import ReferralPage from './Referral.page';
import { referralPath } from '../../../util/lib';
import css from './ReferralPage.module.css';

const ReferralHitw = ({ shop }) => {
  const {code} = useParams();
  if (!shop) {
    return <div>Loading...</div>;
  }
  const successPage = referralPath(code, 'form');
  const textContent = (
    <ol className={css.HitwSteps}>
      <li>Enter your Name and Mobile</li>
      <li>You will receive an SMS with a link for you to share with your friends</li>
      <li>With the link, your friend can use it to get a code to redeem the reward</li>
      <li>Once your friend used their reward, you will get an SMS with a code to redeem your reward</li>
    </ol>
  );
  return (
    <ReferralPage
      title='How it works'
      className={css.Hitw}
      textContent={textContent}
      successPage={successPage}
      successButton='Start Sharing'
    />
  );
};
export default ReferralHitw;
