import React from 'react';
import { useParams } from 'react-router';

import ReferralPage from './Referral.page';
import { referralPath } from '../../../util/lib';

const OpeningPage = ({ shop }) => {
  const {code} = useParams();
  if (!shop) {
    return <div>Loading...</div>;
  }
  const textContent = shop.referralGift;
  if (!textContent) {
    return (
      <div>
        Sorry, We are not ready yet. We will get back to you soon.
      </div>
    );
  }
  const successPage = referralPath(code, 'form');
  return (
    <ReferralPage
      withHitw
      logo={shop.logo}
      textContent={textContent}
      successPage={successPage}
      successButton='Start Sharing'
    />
  );
};
export default OpeningPage;
