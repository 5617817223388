import React from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default (options) => {
  const [visitor, setVisitor] = React.useState(null);

  React.useEffect(() => {
    // Run on the server side - ignore
    if (typeof window !== 'object') return;

    const load = async () => {
      // We recommend to call `load` at application startup.
      const fp = await FingerprintJS.load();

      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get();

      // This is the visitor identifier:
      setVisitor(result);
      console.log('visitorId', result);
    }
    load();
  }, [options]);

  return visitor;
}
