import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import css from './ThumbsButton.module.css';

const ThumbsButton = (props) => {
	return (
		<button
			{...props}
			className={[css.ThumbsButton, props.thumbsup === "true" && css.MainButton].join(
				' '
			)}
		>
			<FontAwesomeIcon icon={props.thumbsup ? faThumbsUp : faThumbsDown} />
		</button>
	);
};
export default ThumbsButton;
