import React from 'react';
import css from './Content.module.css';

const TextContainer = (props) => (
	<div className={[
		props.className,
		css.TextContainer,
		props.title && css.TitleContainer,
		props.weight === 'light' && css.TextLight,
		props.type === 'error' && css.TextError,
	].join(' ')} {...props}>
		{props.children}
	</div>
);
export default TextContainer;
