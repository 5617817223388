import React from 'react';

import Rating from '../Rating/Rating';
import css from './ReviewResult.module.css';

const ReviewResult = (props) => {
  const {review = {}} = props;
  let firstName = review.firstName;
  if (/qrcode/i.test(firstName)) {
    firstName = '';
  }
  return (
    <div className={css.Container}>
      <p className={css.Title}>
        Hi, {firstName}
      </p>
      <br />
      <p className={css.Intro}>
        Thank you for taking your time to give us your feedback
      </p>
      <Rating rating={review.rating} display />
      <p>
        {review.feedback && (
          <i>
            "{review.feedback}"
          </i>
        )}
      </p>
    </div>
  )
}
export default ReviewResult;
