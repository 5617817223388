import React, { useContext } from 'react';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import Button from '../../UI/Buttons/Button';
import Content from '../../UI/Content/Content';
import { ReferralContext } from '../Referral.store';
import TextContainer from '../../UI/Content/TextContainer';
import ProfilePict from '../../UI/ProfilePict/ProfilePict';
import ButtonsContainer from '../../UI/Buttons/ButtonsContainer/ButtonsContainer';

const ReferralPage = (props) => {
  const {
    logo,
    title,
    withHitw,
    hideButton,
    textContent,
    successPage,
    successButton,
  } = props;
  const { goTo, pageState } = useContext(ReferralContext);

  const onSuccess = async (data) => {
    if (props.onSuccess) {
      props.onSuccess();
    }
    goTo(successPage);
  };
  const onHitw = () => {
    if (props.onHitw) {
      props.onHitw();
    }
    goTo('howitworks');
  };
  // https://ik.imagekit.io/flytree/FlytreeShopsLogo/example-logo__KSeTm0RA.png
  return (
    <Content
      state={pageState}
      className={props.className}>
      {!!logo && (
        <ProfilePict src={logo} />
      )}
      {!!title && <TextContainer title={title}>{title}</TextContainer>}
      <TextContainer>
        {textContent}
      </TextContainer>
      {props.children}
      {!hideButton && (
        <ButtonsContainer flexColumn>
          <Button onClick={onSuccess}>
            <div>
              <FontAwesomeIcon icon={faShare} />
            </div>
            {successButton || 'Yes'}
          </Button>
          {withHitw && (
            <Button onClick={onHitw} type="link" size="small">
              <div>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </div>
              How it works
            </Button>
          )}
        </ButtonsContainer>
      )}
    </Content>
  );
};

export default ReferralPage;
