import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import smartlookClient from 'smartlook-client';
import React, { useContext, useState } from 'react';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Buttons/Button';
import { delay } from '../../../util/ExtraMethods';
import { ReferralContext } from '../Referral.store';
import ReferralPage from '../ReferralPage/Referral.page';
import TextContainer from '../../UI/Content/TextContainer';
import { PhonePattern, referralPath } from '../../../util/lib';
import ButtonsContainer from '../../UI/Buttons/ButtonsContainer/ButtonsContainer';

import css from './RedeemVoucher.module.css';

const trackEvent = process.env.REACT_APP_SMARTLOOK_ID;

const RedeemVoucher = ({ api, shop, referral }) => {
  const {code} = useParams();
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const form = useForm();
  const { goTo, setVoucher } = useContext(ReferralContext);
  if (!referral) {
    return <div>Loading...</div>;
  }
  const successPage = referralPath(code, 'form');
  const onSubmit = form.handleSubmit(async (data) => {
    try {
      if (loading) return;
      setErrorMessage(null);
      setLoading(true);
      await delay(1000);
      const response = await api({
        data,
        method: 'post',
        responseType: 'json',
        url: `/referrals/${code}/redeem`,
      });
      if (trackEvent && response.data && response.data.voucher) {
        smartlookClient.identify(shop.code, response.data.voucher);
      }
      setLoading(false);
      const {voucher} = response.data;
      voucher.statusCode = response.status;
      setVoucher(voucher);
      goTo('thanks');
    } catch (error) {
      console.error(error);
      let errorMessage = 'Failed to redeem voucher';
      if (error.response && error.response.data) {
        if (/self redeem/i.test(error.response.data.message)) {
          errorMessage = `
            You could not redeem this gift by using this phone number
          `;
        }
      }
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  });
  return (
    <ReferralPage
      withHitw
      hideButton
      successButton='Submit'
      successPage={successPage}>
      <div className={css.Wrapper}>
        <TextContainer>
          Enter your details to receive an SMS with a code that you can use to redeem the gift at the shop
        </TextContainer>
        <TextContainer type='error'>{errorMessage}</TextContainer>
        <div className={css.Form}>
          <Input
            form={form}
            name='name'
            placeholder='Enter your name'
            rule={{
              required: 'Please tell us your name.',
              maxLength: {
                value: 50,
                message: 'Are you sure your name is correct?',
              },
              minLength: {
                value: 3,
                message: 'Are you sure your name is correct?',
              },
            }} />
          <Input
            form={form}
            name='phone'
            placeholder='Enter your mobile'
            rule={{
              required: 'Please tell us your mobile number.',
              maxLength: {
                value: 10,
                message: 'Are you sure Mobile number is correct?',
              },
              minLength: {
                value: 10,
                message: 'Are you sure Mobile number is correct?',
              },
              pattern: {
                value: PhonePattern,
                message: 'Are you sure Mobile number is correct?',
              },
            }} />
        </div>
        <ButtonsContainer flexColumn>
          <Button
            type="main"
            variant="square"
            onClick={onSubmit}
            disabled={loading}>
            {loading ? 'Preparing..' : 'Submit'}
          </Button>
        </ButtonsContainer>
      </div>
    </ReferralPage>
  );
};
export default RedeemVoucher;
