import React from 'react';

import HtmlHead from '../../UI/Head/HtmlHead';
import css from './ReviewExample.module.css';

const ReviewExample = () => {
  return (
    <div className={css.Review}>
      <HtmlHead flytree />
      <div className={css.ReviewContent}>
        <div className={css.Container}>
          <p className={css.Title}>
            Oops,
          </p>
          <br />
          <p className={css.Intro}>
            This is a test message, so you won't be redirected
          </p>
        </div>
      </div>
      <div className={css.PoweredBy}>
        Powered by{' '}
        <span
          onClick={() =>
            (window.location.href = 'https://www.flytree.co')
          }
        >
        Flytree
        </span>
      </div>
    </div>
  )
}
export default ReviewExample;
