import React from 'react';
import { Helmet } from 'react-helmet-async';

export default (props) => {
  if (props.flytree) {
    return (
      <Helmet>
        <title>Flytree</title>
        <meta name="description" content="Review Platform" />
        <title>Flytree - stand out and be the obvious choice.</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon-flytree.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-flytree.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-flytree.png"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#4e4e4e"
        />
      </Helmet>
    );
  }
  else if (props.repairy) {
    return (
      <Helmet>
        <meta name="description" content="Instant workshops booking" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon.ico"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#4e4e4e"
        />
        <title>Repairy - Get more bookings and less phone calls.</title>
      </Helmet>
    );
  }
  return (null);
}