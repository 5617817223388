import React from 'react';

import css from './ReferralError.module.css';

const ReferralError = (props) => {
  const {review} = props;
  return (
    <div className={css.Container}>
      {review && (
        <p className={css.Title}>
          Hi there,
        </p>
      )}
      <p className={css.Subtitle}>
        There is a problem while fetching your shop data.
      </p>
      <p className={css.Intro}>
        Please contact support if problem persist.
      </p>
    </div>
  )
}
export default ReferralError;
