import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { useApi } from './util/lib';
import HtmlHead from './components/UI/Head/HtmlHead';

export default () => {
  const api = useApi();
  const { code } = useParams();
  const [error, setError] = useState();
  const [reviewLink, setReviewLink] = useState();
  useEffect(() => {
    if (reviewLink) {
      window.location.href = reviewLink;
    }
  }, [reviewLink]);

  const prepare = useCallback(async() => {
    try {
      setError(false);
      const response = await api({
        method: 'get',
        responseType: 'json',
        url: `/bookings/${code}/review-link`,
      });
      if (!response.data || !response.data.reviewLink) {
        throw new Error('Failed to fetch review link');
      }
      setReviewLink(response.data.reviewLink);
    } catch (error) {
      console.log('error', error.message)
      console.error(error);
      setError(true);
    }
  }, [code, api]);

  useEffect(() => {
    if (!reviewLink && !error) prepare();
  }, [reviewLink, error, prepare]);

  const styles = {
    container: {
      padding: 20,
      display: 'block',
      textAlign: 'center',
    },
    title: {
      fontSize: '1.4em'
    }
  };

  return (
    <div style={styles.container}>
      <HtmlHead repairy />
      {!error && <p style={styles.title}>Redirecting...</p>}
      {error && (
        <>
          <p style={styles.title}>
            There is a problem while fetching your booking #{code}
          </p>
          <p>Please contact Repairy support if problem persist.</p>
        </>
      )}
      {reviewLink && (
        <p>Click <a href={reviewLink}>here</a> if you are not redirected automatically.</p>
      )}
    </div>
  );
}