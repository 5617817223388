import React, { useContext } from 'react';

import Content from '../../UI/Content/Content';
import { ReferralContext } from '../Referral.store';
import { referralRedeemPrefix } from '../../../util/lib';
import TextContainer from '../../UI/Content/TextContainer';
import { AppContext } from '../../../containers/AppContext';
import { ReactComponent as FbLogo } from '../../../components/UI/Images/fb-logo.svg';
import { ReactComponent as TwitterIcon } from '../../../components/UI/Images/twitter-icon.svg';
import css from './ReferralShare.module.css';

const BaseShortURL = process.env.REACT_APP_SHORT_URL;

const ReferralShare = () => {
  const {theme} = useContext(AppContext);
  const {referral} = useContext(ReferralContext);
  if (!referral) {
    return <div>Preparing...</div>;
  }

  const link = `${BaseShortURL}${referralRedeemPrefix}/${referral.code}`;
  const buildLink = template => template.replace(
    '{{link}}', encodeURIComponent(link)
  );

  const channels = [
    // instagram does not allow link sharing right now
    // {
    //   label: 'Instagram',
    //   logo: <FbLogo />,
    //   link: buildLink(`https://instagram.com?test={{link}}`)
    // },
    {
      label: 'Facebook',
      logo: <FbLogo />,
      link: buildLink(`https://www.facebook.com/sharer/sharer.php?u={{link}}`)
    },
    {
      label: 'Twitter',
      logo: <TwitterIcon />,
      link: buildLink(`https://twitter.com/intent/tweet?url={{link}}`)
    },
  ];

  return (
    <Content>
      <TextContainer className={css.ReferralShare}>
        <div className={css.Intro}>
          Thank you for sharing the good news.
        </div>
        <div className={css.Intro}>
          You will receive a link to share shortly.
        </div>
        <div className={css.ShareHint}>
          Or alternatively, you can share it on:
        </div>
        <div
          className={css.ButtonTree}>
          {channels.map(channel => (
            <a
              target='_blank'
              key={channel.label}
              href={channel.link}
              style={theme.button}
              rel='noopener noreferrer'>
              <div>{channel.logo}</div>
              Share on {channel.label}
            </a>
          ))}
        </div>
      </TextContainer>
    </Content>
  );
};
export default ReferralShare;
