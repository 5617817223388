import smartlookClient from 'smartlook-client';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';

import { delay } from '../util/ExtraMethods';
import Greetings from '../components/Review/Greetings/Greetings';
import ReviewForm from '../components/Review/ReviewForm/ReviewForm';
import ReviewTree from '../components/Review/ReviewTree/ReviewTree';
import ReviewError from '../components/Review/ReviewError/ReviewError';
import ReviewResult from '../components/Review/ReviewResult/ReviewResult';
import OpeningPage from '../components/Review/ReviewPage/Opening.review.page';
import RedirectFlytree from '../components/Review/RedirectFlytree/RedirectFlytree';
import {
  useApi,
  reviewPath,
  reviewPrefix,
} from '../util/lib';
import css from './Review.module.css';

const trackEvent = process.env.REACT_APP_SMARTLOOK_ID;

const Review = (props) => {
  const legacy = true;
  const {code} = useParams();
  const history = useHistory();
  const [review, setReview] = useState();
  const [reviewLinks, setReviewLinks] = useState();
  const isOnErrorPage = useRouteMatch(reviewPath(code, 'error', legacy));

  const api = useApi();
  const goTo = useCallback(async (page) => {
    await delay(400);
    const path = reviewPath(code, page, legacy);
    if (page === 'error' && isOnErrorPage) return;
    history.push(path);
  }, [code, history, legacy, isOnErrorPage]);

  const prepare = useCallback(async() => {
    try {
      let response = await api({
        method: 'get',
        responseType: 'json',
        url: `/reviews/${code}`,
        params: { open: true }
      });
      if (!response.data || !response.data.review) {
        throw new Error('Failed to fetch review data');
      }
      const review = response.data.review;
      if (trackEvent) {
        smartlookClient.identify('rpry-' + review.code, {
          phone: review.phone,
          rating: review.rating,
          feedback: review.feedback,
          firstName: review.firstName,
        });
      }
      setReview(review);
      if (review.feedback) {
        goTo('result');
        return;
      }

      response = await api({
        method: 'get',
        responseType: 'json',
        url: '/reviews/links',
        params: {
          partnerId: review.partnerId
        }
      });
      if (!response.data || !response.data.links) {
        throw new Error('Failed to fetch review links');
      }
      setReviewLinks(response.data.links);

      goTo('opening');
    } catch (error) {
      if (trackEvent) smartlookClient.anonymize();
      console.error(error);
      goTo('error');
    }
  }, [code, goTo, api]);

  useEffect(() => {
    if (!review) prepare();
  }, [prepare, review]);

  const onReviewed = useCallback(async (data) => {
    setReview({ ...review, ...data });
    goTo('result');
  }, [review, goTo]);

  const prefix = reviewPrefix(legacy);
  const context = { api, legacy, review, reviewLinks };
  return (
    <div className={css.Review}>
      <div className={css.ReviewContent}>
        <Route path={`${prefix}/:code/opening`} exact>
          <OpeningPage {...context} />
        </Route>
        <Route path={`${prefix}/:code/error`} exact>
          <ReviewError {...context} />
        </Route>
        <Route path={`${prefix}/:code/result`} exact>
          <ReviewResult {...context} />
        </Route>
        <Route path={`${prefix}/:code/tree`} exact>
          <ReviewTree {...context} />
        </Route>
        <Route path={`${prefix}/:code/review-form-bad`} exact>
          <ReviewForm
            {...context}
            onReviewed={onReviewed}
            defaultTitle='We are sorry to hear you had a negative experience, please let us know what was wrong' />
        </Route>
        <Route path={`${prefix}/:code/review-form`} exact>
          <ReviewForm
            {...context}
            onReviewed={onReviewed}
            defaultTitle="Please let us know, did we do well?" />
        </Route>
        <Route path={`${prefix}/:code/redirect/:platform`} exact>
          <RedirectFlytree {...context} />
        </Route>
        <Route path={`${prefix}/:code`} exact>
          <Greetings {...context}/>
        </Route>
        <div className={css.PoweredBy}>
          This invitation is powered by{' '}
          <span
            onClick={() =>
              (window.location.href = 'https://www.repairy.com.au')
            }
          >
          Repairy
          </span>
        </div>
      </div>
    </div>
  );
};
export default Review;
