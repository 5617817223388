import smartlookClient from 'smartlook-client';
import React, { useCallback, useContext, useEffect } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';

import { AppContext } from './AppContext';
import { delay } from '../util/ExtraMethods';
import useFingerprint from '../util/useFingerprint';
import Greetings from '../components/Review/Greetings/Greetings';
// import ReferralGreetings from '../components/Referral/ReferralGreetings';
import ReferralHitw from '../components/Referral/ReferralPage/ReferralHitw';
import OpeningPage from '../components/Referral/ReferralPage/ReferralOpening';
import ReferralError from '../components/Referral/ReferralError/ReferralError';
import ReferralShare from '../components/Referral/ReferralShare/ReferralShare';
import ReferralProvider, { ReferralContext } from '../components/Referral/Referral.store';
import ReferralLinkRequest from '../components/Referral/ReferralLinkRequest/ReferralLinkRequest';
import {
  useApi,
  referralPath,
  referralPrefix,
  usePartnerTheme,
} from '../util/lib';

import css from './Review.module.css';

const prefix = referralPrefix;
const trackEvent = process.env.REACT_APP_SMARTLOOK_ID;

const Referral = () => {
  usePartnerTheme();
  const urlParams = useParams();
  const {code, refCode} = urlParams;
  const history = useHistory();
  const {
    referral,
    setReferral,
  } = useContext(ReferralContext);
  const {
    shop,
    theme,
    setShop
  } = useContext(AppContext);

  const isOnGreeting = useRouteMatch(referralPath(code));
  const isOnErrorPage = useRouteMatch(referralPath(code, 'error'));
  const isOnReferralPage = useRouteMatch(
    referralPath(code, `share/${refCode}`)
  );

  const api = useApi('flytree');
  const visitor = useFingerprint();
  const goTo = useCallback(async (page) => {
    await delay(400);
    const path = referralPath(code, page);
    if (page === 'error' && isOnErrorPage) return;
    history.push(path);
  }, [history, code, isOnErrorPage]);

  const prepare = useCallback(async() => {
    try {
      if (!visitor) return;
      if (isOnGreeting) {
        await delay(2000);
      }
      if (code.trim().toLowerCase() === 'sample') {
        setShop({ code });
        goTo('opening');
        return;
      }

      if (refCode) {
        const response = await api({
          method: 'get',
          responseType: 'json',
          url: `/referrals/${refCode}`,
          params: {
            visitorId: visitor.visitorId,
            timezone: visitor.components.timezone.value,
            platform: visitor.components.platform.value,
          }
        });
        if (!response.data || !response.data.referral) {
          throw new Error('Failed to fetch referral data');
        }
        setReferral(response.data.referral);
        setShop(response.data.partner);

        if (!isOnReferralPage) {
          goTo(`share/${refCode}`);
        }
        return;
      }

      const response = await api({
        method: 'get',
        responseType: 'json',
        url: `/shops/${code}`,
        params: {
          visitorId: visitor.visitorId,
          timezone: visitor.components.timezone.value,
          platform: visitor.components.platform.value,
        }
      });
      if (!response.data || !response.data.shop) {
        throw new Error('Failed to fetch shop data');
      }
      setShop(response.data.shop);
      goTo('opening');
    } catch (error) {
      if (trackEvent) smartlookClient.anonymize();
      console.error(error);
      goTo('error');
    }
  }, [
    api,
    code,
    goTo,
    refCode,
    setShop,
    visitor,
    setReferral,
    isOnGreeting,
    isOnReferralPage,
  ]);

  useEffect(() => {
    if (!shop && !referral) prepare();
  }, [prepare, shop, referral]);

  const context = { api, shop, goTo };
  return (
    <div className={css.Review}>
      <div className={css.ReviewContent}>
        <Route path={`${prefix}/:code/opening`} exact>
          <OpeningPage {...context} />
        </Route>
        <Route path={`${prefix}/:code/error`} exact>
          <ReferralError {...context} />
        </Route>
        <Route path={`${prefix}/:code/form`} exact>
          <ReferralLinkRequest {...context} />
        </Route>
        <Route path={`${prefix}/:code/howitworks`} exact>
          <ReferralHitw {...context} />
        </Route>
        <Route path={`${prefix}/:code/share/:refCode`} exact>
          <ReferralShare {...context} />
        </Route>
        <Route path={`${prefix}/:code`} exact>
          <Greetings {...context}/>
        </Route>
        <div
          style={theme}
          className={css.PoweredBy}>
          Powered by{' '}
          <span
            onClick={() =>
              (window.location.href = 'https://www.flytree.co')
            }
          >
          Flytree
          </span>
        </div>
      </div>
    </div>
  );
};

export default () => {
  return (
    <ReferralProvider>
      <Referral />
    </ReferralProvider>
  );
};
