import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import smartlookClient from 'smartlook-client';
import React, { useContext, useState } from 'react';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Buttons/Button';
import { delay } from '../../../util/ExtraMethods';
import { ReferralContext } from '../Referral.store';
import ReferralPage from '../ReferralPage/Referral.page';
import TextContainer from '../../UI/Content/TextContainer';
import { PhonePattern, referralPath } from '../../../util/lib';
import ButtonsContainer from '../../UI/Buttons/ButtonsContainer/ButtonsContainer';

import css from './ReferralLinkRequest.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const trackEvent = process.env.REACT_APP_SMARTLOOK_ID;

const ReferralLinkRequest = ({ api, shop }) => {
	const { code } = useParams();
	const [loading, setLoading] = useState();
	const form = useForm();
	const { goTo, setReferral } = useContext(ReferralContext);
	if (!shop) {
		return <div>Loading...</div>;
	}
	const successPage = referralPath(code, 'form');
	const onSubmit = form.handleSubmit(async (data) => {
		try {
			if (loading) return;
			setLoading(true);
			await delay(1000);
			const response = await api({
				data,
				method: 'post',
				responseType: 'json',
				url: `/referrals/${code}`,
			});
			if (trackEvent && response.data && response.data.referral) {
				smartlookClient.identify(shop.code, response.data.referral);
			}
			setLoading(false);
			const { referral } = response.data;
			setReferral(referral);
			goTo(`share/${referral.code}`);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	});
	const onHitw = () => {
		goTo('howitworks');
	};
	return (
		<ReferralPage
			withHitw
			hideButton
			successButton="Submit"
			successPage={successPage}
		>
			<div className={css.Wrapper}>
				<TextContainer>
					We will send you a link that you can share with friends.
				</TextContainer>
				<div className={css.Form}>
					<Input
						form={form}
						name="name"
						placeholder="Enter your name"
						rule={{
							required: 'Please tell us your name.',
							maxLength: {
								value: 50,
								message: 'Are you sure your name is correct?',
							},
							minLength: {
								value: 3,
								message: 'Are you sure your name is correct?',
							},
						}}
					/>
					<Input
						form={form}
						name="phone"
						placeholder="Enter your mobile"
						rule={{
							required: 'Please tell us your mobile number.',
							maxLength: {
								value: 10,
								message: 'Are you sure Mobile number is correct?',
							},
							minLength: {
								value: 10,
								message: 'Are you sure Mobile number is correct?',
							},
							pattern: {
								value: PhonePattern,
								message: 'Are you sure Mobile number is correct?',
							},
						}}
					/>
				</div>
				<ButtonsContainer flexColumn style={{marginTop:'0px'}}>
					<Button
						type="main"
						variant="square"
						onClick={onSubmit}
						disabled={loading}
					>
						<div>
							<FontAwesomeIcon icon={faGift} />
						</div>
						{loading ? 'Preparing..' : 'Send me the link'}
					</Button>
					<Button onClick={onHitw} type="link" size="small">
						<div>
							<FontAwesomeIcon icon={faQuestionCircle} />
						</div>
						How it works
					</Button>
				</ButtonsContainer>
			</div>
		</ReferralPage>
	);
};
export default ReferralLinkRequest;
