import React from 'react';
import Content from '../../UI/Content/Content';
import TextContainer from '../../UI/Content/TextContainer';
import LoadingAnimation from '../../UI/LoadingAnimation/LoadingAnimation';
import css from './Greetings.module.css';

const Greetings = (props) => {
	return (
		<Content>
			<TextContainer>
				{/* <img
					src="https://ik.imagekit.io/flytree/AssetKit/flytree-loading-2_3zNC8txhU.svg"
					className={css.Loading}
					alt="loading"
				/> */}
				<LoadingAnimation className={css.Loading} />
				<div className={css.WaitMessage}>While you wait..</div>
				<div className={css.QuoteMessage}>
					"What is a tree’s least favourite month? Sep-timber!"
				</div>
			</TextContainer>
		</Content>
	);
};
export default Greetings;
