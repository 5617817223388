import React, { useCallback, useEffect, useContext } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';

import { AppContext } from './AppContext';
import { delay } from '../util/ExtraMethods';
import useFingerprint from '../util/useFingerprint';
import Greetings from '../components/Review/Greetings/Greetings';
import ReferralError from '../components/Referral/ReferralError/ReferralError';
import RedeemVoucher from '../components/Referral/RedeemVoucher/RedeemVoucher';
import RedeemSuccess from '../components/Referral/ReferralRedeem/RedeemSuccess';
import OpeningPage from '../components/Referral/ReferralRedeem/ReferralRedeemOpening';
import ReferralProvider, { ReferralContext } from '../components/Referral/Referral.store';
import {
  useApi,
  usePartnerTheme,
  referralRedeemPath,
  referralRedeemPrefix,
} from '../util/lib';

import css from './Review.module.css';

const prefix = referralRedeemPrefix;

const ReferralRedeem = () => {
  usePartnerTheme();
  const {code} = useParams();
  const history = useHistory();
  const {
    voucher,
    referral,
    setReferral,
  } = useContext(ReferralContext);
  const {
    shop,
    theme,
    setShop,
  } = useContext(AppContext);

  const isOnGreeting = useRouteMatch(referralRedeemPath(code));
  const isOnErrorPage = useRouteMatch(referralRedeemPath(code, 'error'));

  const api = useApi('flytree');
  const visitor = useFingerprint();
  const goTo = useCallback(async (page) => {
    await delay(400);
    const path = referralRedeemPath(code, page);
    if (page === 'error' && isOnErrorPage) return;
    history.push(path);
  }, [history, code, isOnErrorPage]);

  const prepare = useCallback(async() => {
    try {
      if (!visitor) return;
      if (isOnGreeting) {
        await delay(2000);
      }
      if (code.trim().toLowerCase() === 'voucherkopi') {
        setReferral({ code });
        goTo('opening');
        return;
      }

      const response = await api({
        method: 'get',
        responseType: 'json',
        url: `/referrals/${code}`,
        params: {
          open: true,
          visitorId: visitor.visitorId,
          timezone: visitor.components.timezone.value,
          platform: visitor.components.platform.value,
        }
      });
      if (!response.data || !response.data.referral) {
        throw new Error('Failed to fetch referral data');
      }
      setReferral(response.data.referral);
      setShop(response.data.partner);
      goTo('opening');
    } catch (error) {
      console.error(error);
      goTo('error');
    }
  }, [
    api,
    code,
    goTo,
    setShop,
    visitor,
    setReferral,
    isOnGreeting,
  ]);

  useEffect(() => {
    if (!referral) prepare();
  }, [prepare, referral]);


  const context = { api, shop, referral, voucher, goTo };
  return (
    <div className={css.Review}>
      <div className={css.ReviewContent}>
        <Route path={`${prefix}/:code/opening`} exact>
          <OpeningPage {...context} />
        </Route>
        <Route path={`${prefix}/:code/error`} exact>
          <ReferralError {...context} />
        </Route>
        <Route path={`${prefix}/:code/form`} exact>
          <RedeemVoucher {...context} />
        </Route>
        <Route path={`${prefix}/:code/thanks`} exact>
          <RedeemSuccess {...context} />
        </Route>
        <Route path={`${prefix}/:code`} exact>
          <Greetings {...context}/>
        </Route>
        <div
          style={theme}
          className={css.PoweredBy}>
          Powered by{' '}
          <span
            onClick={() =>
              (window.location.href = 'https://www.flytree.co')
            }
          >
          Flytree
          </span>
        </div>
      </div>
    </div>
  );
};

export default () => {
  return (
    <ReferralProvider>
      <ReferralRedeem />
    </ReferralProvider>
  );
};
