import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useParams, useRouteMatch } from 'react-router';
import { useCallback, useContext, useEffect } from 'react';

import { AppContext } from '../containers/AppContext';

export const generatePubKey = async (app = 'repairy') => {
  const key = process.env.REACT_APP_API_PUBLIC_KEY
  const secret = process.env.REACT_APP_API_PUBLIC_KEY_SECRET
  const token = await jwt.sign({key}, secret, { expiresIn: '1d' })
  return token
}

export const getApi = async ({ baseURL }) => {
  const token = await generatePubKey();
  const instance = axios.create({
    baseURL,
    headers: {'X-App-Key': token}
  });
  return instance;
}

export const prepareApi = async () => {
  const token = await generatePubKey();
  axios.defaults.headers['X-App-Key'] = token;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
}

export const AppName = process.env.REACT_APP_NAME;
export const reviewPrefix = legacy => legacy ? '/r' : '';
export const reviewPath = (code, page = '', legacy) => {
  const prefix = reviewPrefix(legacy);
  return `${prefix}/${code}/${page}`;
}

export const preparePubKey = async () => {
  const token = await generatePubKey();
  return token
}

export const useApi = () => {
  const {pubKey} = useContext(AppContext);
  const baseURL = process.env.REACT_APP_API_URL
  const instance = axios.create({
    baseURL,
    headers: {'X-App-Key': pubKey}
  });
  const api = useCallback(async (params) => {
    const result = await instance(params);
    return result;
  }, [instance]);
  return api;
}

export const referralPrefix = '/s';
export const referralPath = (code, page = '') => {
  return `${referralPrefix}/${code}/${page}`;
}

export const referralRedeemPrefix = '/v';
export const referralRedeemPath = (code, page = '') => {
  return `${referralRedeemPrefix}/${code}/${page}`;
}

// eslint-disable-next-line
export const PhonePattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

// eslint-disable-next-line
export const EmailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const usePartnerTheme = () => {
  const { code } = useParams();
  const isReferralGreeting = useRouteMatch({
    exact: true,
    path: referralPath(code),
  });
  const isRedeemGreeting = useRouteMatch({
    exact: true,
    path: referralRedeemPath(code),
  });
  const isReviewGreeting = useRouteMatch({
    exact: true,
    path: reviewPath(code),
  });
  const isGreeting = (
    isReviewGreeting ||
    isRedeemGreeting ||
    isReferralGreeting
  );
  const { shop, setTheme } = useContext(AppContext);
  useEffect(() => {
    if (!shop || !shop.theme) return;
    const theme = shop.theme;
    const themeStyle = {
      color: theme.fontColor,
      backgroundColor: theme.bgColor,
      button: {
        color: theme.buttonTextColor,
        backgroundColor: theme.buttonBgColor,
      }
    };
    if (!isGreeting) {
      setTheme(themeStyle);
    }
  }, [shop, setTheme, isGreeting]);
}
