import React from 'react';
import css from './Content.module.css';

const Content = (props) => {
	return (
		<div
			{...props}
			className={[
				css.Content,
				props.className,
				props.state === 0 && css.FadeOut,
			].join(' ')}
		>
			{props.children}
		</div>
	);
};
export default Content;
