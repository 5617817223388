import React from 'react';
import css from './ProfilePict.module.css';

const ProfilePict = (props) => (
	<div {...props} className={css.ProfileContainer}>
		<img
			src={props.src}
			alt="Profile"
			className={[
				css.Profile,
				css['Profile_' + props.type],
			].join(' ')}
			/>
	</div>
);
export default ProfilePict;
