import React from 'react';
import css from './ReviewLogo.module.css';

const ReviewLogo = (props) => (
	<div
		{...props}
		className={[css.ReviewLogo, props.className].join(' ')}
		src={props.src}
	>
		{props.children}
	</div>
);
export default ReviewLogo;
